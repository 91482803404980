.gt-app-card {
  @include styleclass('m-3');
}

.p-card {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  .p-card-title {
    @include styleclass('gt_font_h5');
    padding: 0.75rem;
  }

  .p-card-subtitle {
    @include styleclass('gt_font_caption');
  }
}
