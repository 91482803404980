@import '../tokens/colors.scss';

.gt_roadmap_table {
  .paint {
    width: 100%;
    background-color: $bars_color;
    padding: 0.7rem;
    display: inline-block;
    box-shadow: rgba(17, 17, 26, 0.2) 0px 3px 0px;
  }

  .slots {
    padding: 0.5rem 0rem 0.5rem 0rem !important;
  }
}

.gt_roadmap_table_search {
  margin-top: 0.5rem;
  top: 0;
}
