@import 'primeflex/primeflex.scss';

.gt_expander {
  @include styleclass('transition-transform transition-duration-500');

  &.expanded {
    @include styleclass('rotate-180');
  }
}

.gt_icon_primary {
  color: $font_color;
}

.gt_icon_secondary {
  color: $font_color_light;
}

.gt_icon_clickable {
  cursor: pointer;
}

.gt_circle {
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: $primary_color;
  color: white;
  font-weight: 600;
}
