$primary_color: #0033a0;
$secondary_color: #00aec7;
$surface_color: #eeeeee;
$bars_color: #85b15e;

$obs_green: #85b15e;
$obs_red: #e74c3c;
$obs_yellow: #f1c40f;
$obs_orange: #e67e22;

$font_color: #53565a;
$font_color_light: #ffff;
$primary_background_color: $primary_color;
$secondary_background_color: $secondary_color;
