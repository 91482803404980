//  ████████╗██╗···██╗██████╗··██████╗·
//  ╚══██╔══╝╚██╗·██╔╝██╔══██╗██╔═══██╗
//  ···██║····╚████╔╝·██████╔╝██║···██║
//  ···██║·····╚██╔╝··██╔═══╝·██║···██║
//  ···██║······██║···██║·····╚██████╔╝
//  ···╚═╝······╚═╝···╚═╝······╚═════╝·

$typo-h1: 3rem;
$typo-h2: 2.25rem;
$typo-h3: 2rem;
$typo-h4: 1.63rem;
$typo-h5: 1.38rem;
$typo-sub-two: 1rem;
$typo-sub-one: 1.13rem;
$typo-small: 0.75rem;
$typo-body: 1rem;
$typo-button: 1rem;
$typo-caption: 0.88rem;
