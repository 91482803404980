@import '../tokens/typo.scss';

.gt_font_small {
  font-size: $typo-small;
}

.gt_font_caption {
  font-size: $typo-body;
}

.gt_font_h5 {
  font-size: $typo-h5;
}

.gt_font_sub1 {
  font-size: $typo-sub-one;
}

.gt_font_sub2 {
  font-size: $typo-sub-two;
}

.gt_font_body {
  font-size: $typo-body;
}

span {
  font-size: $typo-body;
}
