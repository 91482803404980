.gt_metadata_list {
  li {
    list-style: none;
    padding: 0.5rem;

    i {
      margin-right: 0.5rem;
    }
  }
}
