@import '../tokens/colors.scss';

.gt_sidebar {
  width: 200px;
  height: 100vh;
}

.gt_sidebar_submenu {
  list-style: none;
  padding: 5px 0px;
  overflow: hidden;
  list-style-type: none;
  margin: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.gt_sidebar_item a:hover {
  background-color: $secondary_background_color;
}

.gt_sidebar_item_active > a > .pi-angle-down {
  transform: rotate(180deg);
}

.gt_sidebar_item_arrow {
  float: right;
}

.gt_sidebar_item_link {
  padding: 5px 10px;
  color: #535353;
  position: relative;
  display: block;
  text-decoration: none;
  border: 1px solid transparent;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.gt_sidebar_item_link .pi-angle-down {
  transition: all 0.3s;
  transition-duration: ease-in-out;
}

.gt_sidebar_item_link i {
  margin-right: 5px;
}
