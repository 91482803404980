.gt_timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: var(--surface-300);
  top: 0;
  bottom: 0;
  left: 56%;
  z-index: 1;
}
