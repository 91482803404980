@import '../tokens/colors.scss';

.gt_font_color_primary {
  color: $font_color;
}

.gt_font_color_secondary {
  color: $font_color_light;
}

.gt_bg_color_primary {
  background-color: $primary_background_color;
}

.gt_bg_color_secondary {
  background-color: $secondary_background_color;
}

.gt_bg_surface {
  background-color: $surface_color;
}

.gt_color_green {
  color: $obs_green;
}

.gt_color_red {
  color: $obs_red;
}

.gt_color_yellow {
  color: $obs_yellow;
}

.gt_color_orange {
  color: $obs_orange;
}
