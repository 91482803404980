/* You can add global styles to this file, and also import other style files */
@import './assets/scss/theme.scss';

/*
 * Base structure
 */
html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
  font-size: $typo-body;
}

/*
   * Typography
   */

h1 {
  margin-bottom: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid white;
}
